import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Service from "../../templates/Service";

const Services = ({ data }) => {
  const services = data;
  return (
    <div className="bg-grey" id="services">
      <div className="px-5 py-24 max-w-6xl mx-auto ">
        <h2 className="uppercase text-black font-medium text-5xl Baloo">
          Services
        </h2>
        <div className="w-full flex flex-wrap py-12">
          {services?.map(({ node: { frontmatter: data } }, index) => {
            return <Service data={data} order={index % 2 === 0 ? 1 : 2} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
